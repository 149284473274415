// Here you can add other styles
.bodybackg {
  background-color: #ffffff;
}

// ----- LOGIN PAGE ----- //

.login-bg {
  background: rgba(255, 255, 255, 0.75);
  background-size: cover;
}
.login-bg-eff {
  background: rgba(255, 255, 255, 0.75);
  // background: linear-gradient(-45deg, rgba(144, 209, 207, 0.9), rgba(0, 161, 156, 0.7), rgba(0, 85, 85, 0.9));
background-size: 200% 200%;
-webkit-animation: Gradient 5s ease infinite;
-moz-animation: Gradient 5s ease infinite;
animation: Gradient 5s ease infinite;
}

@keyframes Gradient {
0% {
  background-position: 0% 50%
}
50% {
  background-position: 100% 50%
}
100% {
  background-position: 0% 50%
}
}
// ----- End Login Pge -----//

.bg-light-grey {
  color: #333;
  background: #e4e5e6;
}
.bg-grey {
  color: #333;
  background: #c3c3c3;
}
.bg-trans {
  background: transparent !important;
}
.bg-ldark {
  background: #444444;
  color: #ffffff;
}
.bg-p {
  background: #3e3935;
  color: #ffffff;
}

.app-header .navbar-brand {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.navbar {
  padding: 0 !important;
}

.breadcrumb {
  background-color: #f1f1f1;
}

.app-footer {
  background-color: #f1f1f1;
}

.nav-dropdown-items .nav-item a {
  padding-left: 2.5rem;
  border-left: 5px $primary solid !important;
}

.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.text-p {
  color: #3e3935;
}

.hover-shadow {
  transition: all ease-in-out $layout-transition-speed;
}
.hover-shadow:hover {
  filter: drop-shadow(0px 0px 2px rgba(0,0,0,0.45));
}

.hover-opa {
  opacity: 0.7;
  transition: all ease-in-out $layout-transition-speed;
}
.hover-opa:hover {
  opacity: 1;
}
.hover-opa-r {
  opacity: 1;
  transition: all ease-in-out $layout-transition-speed;
}
.hover-opa-r:hover {
  opacity: 0.7;
}
.hover-scale {
  transform: scale(1);
  transition: all ease-in-out $layout-transition-speed;
}
.hover-scale:hover {
  transform: scale(1.1);
}

@keyframes blinker {
  50% {
       opacity: 0;
  }
}

.blink-it {
  animation: blinker 1s linear infinite;
}
.blink-it:hover {
  animation: none;
}

.opacity-70 {
  opacity: 0.7;
}

.skew-x {
  -ms-transform: skew(-10deg);
  -webkit-transform: skew(-10deg);
  transform: skew(-10deg);
}

.w-90 {
  width: 90%;
}
.h-90 {
  height: 90%;
}
.h-88 {
  height: 88%;
}

.sm-box {
  width: 50px;
  transition: all ease-in-out $layout-transition-speed;
  background:
      // linear-gradient(
      //     135deg,
      //     rgba(255, 255, 255, 0.2),
      //     rgba(255, 255, 255, 0.2) 10px,
      //     rgba(255, 255, 255, 0.3) 10px,
      //     rgba(255, 255, 255, 0.3) 20px
      // )
      linear-gradient(335deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.1) 49.92%, rgba(0,0,0,0.1) 50%);
}

.border-l-2 {
  border-left: 2px #bbb outset;
}

.table-pgn ul {
  margin-bottom: 0;
}

.border-w-2 {
  border-width: 2px !important;
}

.card-footer {
  background-color: #f1f1f1;
}

.mid-card-cont {
  width: 95%;
  margin: auto;
  border-radius: 15px;
  background-color: #e4e5e6;
  font-family: "poppins";
  font-size: 1rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
  display: flex;
  transition: all ease-in-out $layout-transition-speed;
  cursor: pointer;
}
.mid-card-cont:hover {
  filter: drop-shadow(0px 0px 3px rgba(0,0,0,0.2));
  opacity: 0.9;
}

.mid-card-title {
  flex: 2;
  padding: 8px;
  text-align: center;
}
.mid-card-value {
  flex: 1;
  background: #333;
  color: #ffffff;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mini-card {
  width: 100%;
  margin: auto;
  background-color: #ffffff;
  font-family: "poppins";
  font-size: 0.7rem;
  overflow: hidden;
}

a .hover-red {
  color: $dark;
}

a .hover-red:hover {
  color: $danger;
}

.radius-30 {
  border-radius: 30px;
}
.radius-l-30 {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}


.table-col-head {
  background-color: #dddddd;
  font-family: "poppins";
  font-size: 0.8rem;
  padding: 0.6rem 0.3rem !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}
.table-striped tbody tr:nth-of-type(odd):hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.rbt-input-hint-container .form-control {
  border-radius: 15px !important;
  padding-left: 0.7rem;
}
.rbt-input-hint-container .form-control-sm {
  height: calc(2.0625rem + 2px);
}

.progress {
  background-color: rgba(0, 0, 0, 0.1);
}

.pagination {
  width: fit-content;
  margin: auto;
}

.speedometer .current-value {
  display: none;
}

.dark-btn {
  background-color: #111111;
  border-color: #111111;
  color: #999999;
}
.filter-toggler:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.3);
}

.react-bootstrap-table tr {
  cursor: pointer;
}
.react-bootstrap-table .align-middle td, .react-bootstrap-table .align-middle th {
  vertical-align: middle;
}
.table-img-cont {
  width: 50px;
  height: 50px;
  border-radius: 30px;
  overflow: hidden;
  margin: auto;
  filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.1));
}

.graph-control-prev, .graph-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
}
.graph-control-prev {
  left: 0;
}
.graph-control-next {
  right: 0;
}
.graph-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
}
.graph-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
}
.graph-control-prev:hover, .graph-control-prev:focus, .graph-control-next:hover, .graph-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: none;
  opacity: 0.9;
}

.cluster-marker {
font-size:x-large;
color: #fff;
background: #1978c8;
border-radius: 50%;
padding: 30px;
display: flex;
justify-content: center;
align-items: center;
}

.crime-marker {
background: none;
border: none;
}

.crime-marker img {
width: 0px;
}

.btn-bot  {
  position:absolute; 
  margin-left:-35px;
  left:50%;
  // width:100px;
  bottom:0px;
}

#floating-panel {
position: absolute;
top: 10px;
left: 25%;
z-index: 5;
background-color: #fff;
padding: 5px;
border: 1px solid #999;
text-align: center;
font-family: 'Roboto','sans-serif';
line-height: 30px;
padding-left: 10px;
}

// .split {
//   height: 100%;
//   width: 50%;
//   position: fixed;
//   z-index: 1;
//   top: 0;
//   overflow-x: hidden;
//   padding-top: 20px;
// }

// .left {
//   left: 0;
//   background-color: #111;
// }

// .right {
//   right: 0;
//   background-color: red;
// }

.boxx{
font-size: 120%;
display: block;
// background-color: #1978c8;
// text-align: center;
margin: auto;
width: 250px;
height: auto;
// border: 1px solid blue;
position: absolute;
box-sizing: border-box;
overflow: hidden;
top: 0;
left: 0;
transform: translate(-47%,-180%);
background-color: 'rgba(0, 0, 0, 0.995)';
border-radius: 9px;
box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
}

.nav-menu-user{
background-color: white
}

.nav-menu-user:hover {
background-color: rgba(12, 107, 142, 0.295)
}

.centerElement{
text-align: center;
display: block;
justify-content: center;
align-items: center;
margin: auto;
width: 100%;
}

// .profile-pic:hover {
//   opacity: 0.5;
// }

// .profile-pic:focus{
//   opacity: 0.5;
//   outline: #1978c8;
// }

.container {
  position: relative;
  // width: 50%;
}

.profile-pic {
  opacity: 1;
  // display: block;
  width: 100%;
  height: auto;
  transition: .1s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .1s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container:hover .profile-pic {
  opacity: 0.3;
  border-style: solid;
}

.container:hover .middle {
  opacity: 1;
}

.showtext {
  // background-color: ;
  color: black;
  font-size: 16px;
  padding: 16px 32px;
}

.fixedButton{
position: fixed;
bottom: -28px;
right: 40px;
padding: 30px;
}
.roundedFixedBtn{
height: 60px;
line-height: 80px;
width: 60px;
font-size: 2em;
font-weight: bold;
border-radius: 50%;
background-color: #4CAF50;
color: white;
text-align: center;
cursor: pointer;
}

.baseToggle{
  position: fixed;
  bottom: 30px;
  right: 60px;
  padding: 0px;
  outline: transparent;
  }
  .roundedFixedBtn{
  height: 60px;
  line-height: 80px;
  width: 60px;
  font-size: 2em;
  font-weight: bold;
  border-radius: 50%;
  background-color: #4CAF50;
  color: white;
  text-align: center;
  cursor: pointer;
  }

  .cardMarker{
    position: fixed;
    top: 170px;
    left: 80px;
    padding: 0px;
  }

.zoom:hover{
  transform: scale(1.3);

}

.listCollarActive{
 background-color: rgba(255, 255, 255, 0.495);
}

.listCollarActive:hover{
  background-color: white;
  // transform: scale(1.1);

}

// .fixed-plugin .dropdown-menu li {
//   padding: 2px !important; }

//   .fixed-plugin {
//     position: fixed;
//     right: 0;
//     width: 64px;
//     background: rgba(0, 0, 0, 0.3);
//     z-index: 1031;
//     border-radius: 8px 0 0 8px;
//     text-align: center;
//     top: 120px; }
//     .fixed-plugin li > a,
//     .fixed-plugin .badge {
//       transition: all .34s;
//       -webkit-transition: all .34s;
//       -moz-transition: all .34s; }
//     .fixed-plugin .fa-cog {
//       color: #FFFFFF;
//       padding: 10px;
//       border-radius: 0 0 6px 6px;
//       width: auto; }
//     .fixed-plugin .dropdown-menu {
//       right: 80px;
//       left: auto !important;
//       top: -52px !important;
//       width: 290px;
//       border-radius: 10px;
//       padding: 0 10px; }
//     .fixed-plugin .dropdown .dropdown-menu .nc-icon {
//       top: 2px;
//       right: 10px;
//       font-size: 14px; }
//     .fixed-plugin .dropdown-menu:after,
//     .fixed-plugin .dropdown-menu:before {
//       right: 10px;
//       margin-left: auto;
//       left: auto; }
//     .fixed-plugin .fa-circle-thin {
//       color: #FFFFFF; }
//     .fixed-plugin .active .fa-circle-thin {
//       color: #00bbff; }
//     .fixed-plugin .dropdown-menu > .active > a,
//     .fixed-plugin .dropdown-menu > .active > a:hover,
//     .fixed-plugin .dropdown-menu > .active > a:focus {
//       color: #777777;
//       text-align: center; }
//     .fixed-plugin img {
//       border-radius: 0;
//       width: 100%;
//       height: 100px;
//       margin: 0 auto; }
//     .fixed-plugin .dropdown-menu li > a:hover,
//     .fixed-plugin .dropdown-menu li > a:focus {
//       box-shadow: none; }
//     .fixed-plugin .badge {
//       border: 3px solid #FFFFFF;
//       border-radius: 50%;
//       cursor: pointer;
//       display: inline-block;
//       height: 23px;
//       margin-right: 5px;
//       position: relative;
//       width: 23px; }
//       .fixed-plugin .badge.badge-light {
//         border: 1px solid #E3E3E3; }
//         .fixed-plugin .badge.badge-light.active, .fixed-plugin .badge.badge-light:hover {
//           border: 3px solid #0bf; }
//     .fixed-plugin .badge.active,
//     .fixed-plugin .badge:hover {
//       border-color: #00bbff; }
//     .fixed-plugin .badge-blue {
//       background-color: #51bcda; }
//     .fixed-plugin .badge-green {
//       background-color: #6bd098; }
//     .fixed-plugin .badge-orange {
//       background-color: #51cbce; }
//     .fixed-plugin .badge-yellow {
//       background-color: #fbc658; }
//     .fixed-plugin .badge-red {
//       background-color: #ef8157; }
//     .fixed-plugin h5 {
//       font-size: 14px;
//       margin: 10px; }
//     .fixed-plugin .dropdown-menu li {
//       display: block;
//       padding: 15px 2px;
//       width: 25%;
//       float: left; }
//     .fixed-plugin li.adjustments-line,
//     .fixed-plugin li.header-title,
//     .fixed-plugin li.button-container {
//       width: 100%;
//       height: 35px;
//       min-height: inherit; }
//     .fixed-plugin li.button-container {
//       height: auto; }
//       .fixed-plugin li.button-container div {
//         margin-bottom: 5px; }
//     .fixed-plugin #sharrreTitle {
//       text-align: center;
//       padding: 10px 0;
//       height: 50px; }
//     .fixed-plugin li.header-title {
//       height: 30px;
//       line-height: 25px;
//       font-size: 12px;
//       font-weight: 600;
//       text-align: center;
//       text-transform: uppercase; }
//     .fixed-plugin .adjustments-line p {
//       float: left;
//       display: inline-block;
//       margin-bottom: 0;
//       font-size: 1em;
//       color: #3C4858; }
//     .fixed-plugin .adjustments-line a {
//       color: transparent; }
//       .fixed-plugin .adjustments-line a .badge-colors {
//         position: relative;
//         top: -2px; }
//       .fixed-plugin .adjustments-line a a:hover,
//       .fixed-plugin .adjustments-line a a:focus {
//         color: transparent; }
//     .fixed-plugin .adjustments-line .togglebutton {
//       text-align: center; }
//       .fixed-plugin .adjustments-line .togglebutton .label-switch {
//         position: relative;
//         left: -10px;
//         font-size: 0.7142em;
//         color: #66615B; }
//         .fixed-plugin .adjustments-line .togglebutton .label-switch.label-right {
//           left: 10px; }
//       .fixed-plugin .adjustments-line .togglebutton .toggle {
//         margin-right: 0; }
//     .fixed-plugin .adjustments-line .dropdown-menu > li.adjustments-line > a {
//       padding-right: 0;
//       padding-left: 0;
//       border-bottom: 1px solid #ddd;
//       border-radius: 0;
//       margin: 0; }
//     .fixed-plugin .dropdown-menu > li > a.img-holder {
//       font-size: 16px;
//       text-align: center;
//       border-radius: 10px;
//       background-color: #FFF;
//       border: 3px solid #FFF;
//       padding-left: 0;
//       padding-right: 0;
//       opacity: 1;
//       cursor: pointer;
//       display: block;
//       max-height: 100px;
//       overflow: hidden;
//       padding: 0; }
//       .fixed-plugin .dropdown-menu > li > a.img-holder img {
//         margin-top: auto; }
//     .fixed-plugin .dropdown-menu > li a.switch-trigger:hover,
//     .fixed-plugin .dropdown-menu > li > a.switch-trigger:focus {
//       background-color: transparent; }
//     .fixed-plugin .dropdown-menu > li:hover > a.img-holder, .fixed-plugin .dropdown-menu > li:focus > a.img-holder {
//       border-color: rgba(0, 187, 255, 0.53); }
//     .fixed-plugin .dropdown-menu > .active > a.img-holder,
//     .fixed-plugin .dropdown-menu > .active > a.img-holder {
//       border-color: #00bbff;
//       background-color: #FFFFFF; }
//     .fixed-plugin .btn-social {
//       width: 50%;
//       display: block;
//       width: 48%;
//       float: left;
//       font-weight: 600; }
//     .fixed-plugin .btn-social i {
//       margin-right: 5px; }
//     .fixed-plugin .btn-social:first-child {
//       margin-right: 2%; }
//     .fixed-plugin .dropdown .dropdown-menu {
//       transform-origin: 0 0; }
//       .fixed-plugin .dropdown .dropdown-menu:before {
//         border-bottom: 16px solid rgba(0, 0, 0, 0);
//         border-left: 16px solid rgba(0, 0, 0, 0.2);
//         border-top: 16px solid rgba(0, 0, 0, 0);
//         right: -27px;
//         bottom: 425px; }
//       .fixed-plugin .dropdown .dropdown-menu:after {
//         border-bottom: 16px solid rgba(0, 0, 0, 0);
//         border-left: 16px solid #FFFFFF;
//         border-top: 16px solid rgba(0, 0, 0, 0);
//         right: -26px;
//         bottom: 425px; }
//       .fixed-plugin .dropdown .dropdown-menu:before, .fixed-plugin .dropdown .dropdown-menu:after {
//         content: "";
//         display: inline-block;
//         position: absolute;
//         width: 16px;
//         transform: translateY(-50px);
//         -webkit-transform: translateY(-50px);
//         -moz-transform: translateY(-50px); }
//     .fixed-plugin .dropdown.show-dropdown .show .dropdown-menu .show {
//       transform: translate3d(0, -60px, 0) !important;
//       bottom: auto !important;
//       top: 0 !important; }
//     .fixed-plugin .bootstrap-switch {
//       margin: 0; }

//   .fixed-plugin .show-dropdown .dropdown-menu[x-placement=bottom-start] {
//     -webkit-transform: translate3d(0, -100px, 0) !important;
//     -moz-transform: translate3d(0, -100px, 0) !important;
//     -o-transform: translate3d(0, -100px, 0) !important;
//     -ms-transform: translate3d(0, -100px, 0) !important;
//     transform: translate3d(0, -100px, 0) !important; }
//     .fixed-plugin .show-dropdown .dropdown-menu[x-placement=bottom-start]:before, .fixed-plugin .show-dropdown .dropdown-menu[x-placement=bottom-start]:after {
//       top: 100px; }

//   .fixed-plugin .show-dropdown .dropdown-menu[x-placement=top-start] {
//     -webkit-transform: translate3d(0, 100px, 0) !important;
//     -moz-transform: translate3d(0, 100px, 0) !important;
//     -o-transform: translate3d(0, 100px, 0) !important;
//     -ms-transform: translate3d(0, 100px, 0) !important;
//     transform: translate3d(0, 100px, 0) !important; }

//   .fixed-plugin .show-dropdown.show .dropdown-menu.show[x-placement=bottom-start] {
//     -webkit-transform: translate3d(0, -60px, 0) !important;
//     -moz-transform: translate3d(0, -60px, 0) !important;
//     -o-transform: translate3d(0, -60px, 0) !important;
//     -ms-transform: translate3d(0, -60px, 0) !important;
//     transform: translate3d(0, -60px, 0) !important; }

//   .fixed-plugin .show-dropdown.show .dropdown-menu.show[x-placement=top-start] {
//     -webkit-transform: translate3d(0, 470px, 0) !important;
//     -moz-transform: translate3d(0, 470px, 0) !important;
//     -o-transform: translate3d(0, 470px, 0) !important;
//     -ms-transform: translate3d(0, 470px, 0) !important;
//     transform: translate3d(0, 470px, 0) !important; }



//     @media screen and (max-width: 413px) {
//       .fixed-plugin .dropdown.show-dropdown.show .dropdown-menu.show {
//         width: 225px !important; }
//         .fixed-plugin .dropdown.show-dropdown.show .dropdown-menu.show[x-placement=top-start] {
//           transform: translate3d(0, 400px, 0) !important; }
//         .fixed-plugin .dropdown.show-dropdown.show .dropdown-menu.show:before, .fixed-plugin .dropdown.show-dropdown.show .dropdown-menu.show:after {
//           bottom: 360px !important; } }


//           .fixed-plugin .dropdown .dropdown-menu:before {
//             right: -17px;
//             top: 28%; }

//           .fixed-plugin .dropdown .dropdown-menu:after {
//             right: -16px;
//             top: 28%; }

//           .fixed-plugin .gh-btn,
//           .fixed-plugin .gh-count,
//           .fixed-plugin .gh-ico {
//             float: left; }

//           .fixed-plugin .gh-btn,
//           .fixed-plugin .gh-count {
//             padding: 2px 5px 2px 4px;
//             color: #333;
//             text-decoration: none;
//             white-space: nowrap;
//             cursor: pointer;
//             border-radius: 3px; }

//           .fixed-plugin .gh-btn {
//             background-color: #eee;
//             background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fcfcfc), color-stop(100%, #eee));
//             background-image: -webkit-linear-gradient(top, #fcfcfc 0, #eee 100%);
//             background-image: -moz-linear-gradient(top, #fcfcfc 0, #eee 100%);
//             background-image: -ms-linear-gradient(top, #fcfcfc 0, #eee 100%);
//             background-image: -o-linear-gradient(top, #fcfcfc 0, #eee 100%);
//             background-image: linear-gradient(to bottom, #fcfcfc 0, #eee 100%);
//             filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#eeeeee', GradientType=0);
//             background-repeat: no-repeat;
//             border: 1px solid #d5d5d5; }

//           .fixed-plugin .gh-btn:hover,
//           .fixed-plugin .gh-btn:focus {
//             text-decoration: none;
//             background-color: #ddd;
//             background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #eee), color-stop(100%, #ddd));
//             background-image: -webkit-linear-gradient(top, #eee 0, #ddd 100%);
//             background-image: -moz-linear-gradient(top, #eee 0, #ddd 100%);
//             background-image: -ms-linear-gradient(top, #eee 0, #ddd 100%);
//             background-image: -o-linear-gradient(top, #eee 0, #ddd 100%);
//             background-image: linear-gradient(to bottom, #eee 0, #ddd 100%);
//             filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#dddddd', GradientType=0);
//             border-color: #ccc; }

//           .fixed-plugin .gh-btn:active {
//             background-image: none;
//             background-color: #dcdcdc;
//             border-color: #b5b5b5;
//             box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15); }

//           .fixed-plugin .gh-ico {
//             width: 14px;
//             height: 14px;
//             margin-right: 4px;
//             background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQwcHgiIGhlaWdodD0iNDBweCIgdmlld0JveD0iMTIgMTIgNDAgNDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMTIgMTIgNDAgNDAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiMzMzMzMzMiIGQ9Ik0zMiAxMy40Yy0xMC41IDAtMTkgOC41LTE5IDE5YzAgOC40IDUuNSAxNS41IDEzIDE4YzEgMC4yIDEuMy0wLjQgMS4zLTAuOWMwLTAuNSAwLTEuNyAwLTMuMiBjLTUuMyAxLjEtNi40LTIuNi02LjQtMi42QzIwIDQxLjYgMTguOCA0MSAxOC44IDQxYy0xLjctMS4yIDAuMS0xLjEgMC4xLTEuMWMxLjkgMC4xIDIuOSAyIDIuOSAyYzEuNyAyLjkgNC41IDIuMSA1LjUgMS42IGMwLjItMS4yIDAuNy0yLjEgMS4yLTIuNmMtNC4yLTAuNS04LjctMi4xLTguNy05LjRjMC0yLjEgMC43LTMuNyAyLTUuMWMtMC4yLTAuNS0wLjgtMi40IDAuMi01YzAgMCAxLjYtMC41IDUuMiAyIGMxLjUtMC40IDMuMS0wLjcgNC44LTAuN2MxLjYgMCAzLjMgMC4yIDQuNyAwLjdjMy42LTIuNCA1LjItMiA1LjItMmMxIDIuNiAwLjQgNC42IDAuMiA1YzEuMiAxLjMgMiAzIDIgNS4xYzAgNy4zLTQuNSA4LjktOC43IDkuNCBjMC43IDAuNiAxLjMgMS43IDEuMyAzLjVjMCAyLjYgMCA0LjYgMCA1LjJjMCAwLjUgMC40IDEuMSAxLjMgMC45YzcuNS0yLjYgMTMtOS43IDEzLTE4LjFDNTEgMjEuOSA0Mi41IDEzLjQgMzIgMTMuNHoiLz48L3N2Zz4=");
//             background-size: 100% 100%;
//             background-repeat: no-repeat; }

//           .fixed-plugin .gh-count {
//             position: relative;
//             display: none;
//             /* hidden to start */
//             margin-left: 4px;
//             background-color: #fafafa;
//             border: 1px solid #d4d4d4; }

//           .fixed-plugin .gh-count:hover,
//           .fixed-plugin .gh-count:focus {
//             color: #4183C4; }

//           .fixed-plugin .gh-count:before,
//           .fixed-plugin .gh-count:after {
//             content: '';
//             position: absolute;
//             display: inline-block;
//             width: 0;
//             height: 0;
//             border-color: transparent;
//             border-style: solid; }

//           .fixed-plugin .gh-count:before {
//             top: 50%;
//             left: -3px;
//             margin-top: -4px;
//             border-width: 4px 4px 4px 0;
//             border-right-color: #fafafa; }

//           .fixed-plugin .gh-count:after {
//             top: 50%;
//             left: -4px;
//             z-index: -1;
//             margin-top: -5px;
//             border-width: 5px 5px 5px 0;
//             border-right-color: #d4d4d4; }

//           .fixed-plugin .github-btn-large {
//             height: 30px; }

//           .fixed-plugin .github-btn-large .gh-btn,
//           .fixed-plugin .github-btn-large .gh-count {
//             padding: 3px 10px 3px 8px;
//             font-size: 16px;
//             line-height: 22px;
//             border-radius: 4px; }

//           .fixed-plugin .github-btn-large .gh-ico {
//             width: 20px;
//             height: 20px; }

//           .fixed-plugin .github-btn-large .gh-count {
//             margin-left: 6px; }

//           .fixed-plugin .github-btn-large .gh-count:before {
//             left: -5px;
//             margin-top: -6px;
//             border-width: 6px 6px 6px 0; }

//           .fixed-plugin .github-btn-large .gh-count:after {
//             left: -6px;
//             margin-top: -7px;
//             border-width: 7px 7px 7px 0; }

//           .fixed-plugin .github-btn {
//             display: inline-block; }
//             .fixed-plugin .github-btn .gh-btn .gh-ico {
//               margin-top: 3px; }

//           .fixed-plugin .SocialMediaShareButton {
//             display: inline-block; }

//           @media screen and (max-width: 400px) {
//             .fixed-plugin .dropdown-menu {
//               width: 250px; } }
