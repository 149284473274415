// Variable overrides

$primary:                            #0C6C8E;
$info:                               #1483ac;
$dark:                               #333333;

$gray-300:                           #cfcfcf; // lighten($gray-base, 70%);
$gray-400:                           #acbbbc; // lighten($gray-base, 60%);
$gray-500:                           #8fa5a6; // lighten($gray-base, 50%);
$gray-600:                           #757c7c; // lighten($gray-base, 40%);
$gray-700:                           #5c7373; // lighten($gray-base, 30%);
$gray-800:                           #2f3a3a;
$gray-900:                           #3e3935; //DARK components

// Costum Colors

$body-bg:                            #e4e5e6;
$gray-body:                          #e4e5e6;
$table-dark-bg:                      #444444;

// Custom Styles
$navbar-height:                       70px;
$navbar-brand-width:                  210px;
$navbar-brand-minimized-width:        40px;
$navbar-brand-color: #fff;

$sidebar-nav-link-active-color:    $primary;
$sidebar-nav-link-color:              #757c7c;
$sidebar-width:                       250px;
$sidebar-minimized-width:             60px;
$sidebar-bg:                         #fff;
$sidebar-nav-title-padding-y:         1rem;
$sidebar-nav-title-padding-x:         1.5rem;
$sidebar-nav-link-padding-y:          1rem;
$sidebar-nav-link-padding-x:          1.5rem;
$sidebar-nav-link-hover-bg:           $primary;
$sidebar-nav-link-hover-icon-color:   #fff;
$sidebar-nav-link-active-bg:          rgba(12, 108, 142, 0.2);
$sidebar-nav-link-active-icon-color:  $primary;

